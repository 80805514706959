.RichEditor-editor .public-DraftEditorPlaceholder-root{
  color: #BFC6CF;
}
  
.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}
  
.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}
