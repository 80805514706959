/**
 * Datepicker
 */

.flatpickr-calendar.inline {
    display: flex;
}

.flatpickr-calendar {
    width: 328px;
    @apply flex flex-col bg-transparent text-center p-0 text-xs leading-6 rounded box-border;
}

.flatpickr-calendar .numInputWrapper {
    @apply relative;
}

/* Datepicker Header */
.flatpickr-months {
    @apply flex relative mb-5;
}

.flatpickr-current-month {
    @apply flex;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    @apply flex items-center justify-center absolute text-sm w-10 h-10 rounded-full cursor-pointer;
    @apply bg-white dark:bg-icoDarkMode-anthracite border-2 border-icoGray-300 dark:border-none text-icoGray-500;
}

.flatpickr-prev-month.flatpickr-disabled,
.flatpickr-next-month.flatpickr-disabled {
    @apply text-icoGray-300 dark:text-icoDarkMode-beton;
}

.flatpickr-prev-month.flatpickr-disabled button,
.flatpickr-next-month.flatpickr-disabled button {
    @apply cursor-default focus:ring-0 focus:ring-offset-0;
}

.flatpickr-next-month {
    @apply right-0;
}

.flatpickr-prev-month {
    @apply right-12;
}

.flatpickr-month {
    width: calc(100% - 110px);
}

.flatpickr-current-month {
    @apply relative;
}

.flatpickr-current-month .arrowUp,
.flatpickr-current-month .arrowDown {
    @apply absolute inline-block top-1/2 -translate-y-1/2 w-5 h-3 bg-icoGray-100 dark:bg-icoDarkMode-anthracite bg-no-repeat bg-center cursor-pointer;
    background-size: 14px 14px;
}

.flatpickr-current-month .arrowUp:hover,
.flatpickr-current-month .arrowDown:hover {
    @apply bg-icoGray-300 dark:bg-icoDarkMode-beton;
}

.flatpickr-current-month .arrowUp {
    @apply right-2 -mt-1.5;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23899aaf' d='M725.504 684.339l-213.504-223.027-213.504 223.027c-21.402 22.272-56.32 22.272-77.67 0-9.911-10.585-15.998-24.857-15.998-40.55s6.087-29.965 16.028-40.583l-0.031 0.033 252.365-263.578c21.35-22.272 56.32-22.272 77.619 0l252.416 263.578c21.299 22.272 21.299 58.778 0 81.101s-56.32 22.272-77.67 0z'%3E%3C/path%3E%3C/svg%3E");
}

.flatpickr-current-month .arrowDown {
    @apply right-2 mt-1.5;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23899aaf' d='M725.504 339.661l-213.504 223.027-213.504-223.027c-21.402-22.272-56.32-22.272-77.67 0-9.911 10.585-15.998 24.857-15.998 40.55s6.087 29.965 16.028 40.583l-0.031-0.033 252.365 263.578c21.35 22.272 56.32 22.272 77.619 0l252.416-263.578c21.299-22.272 21.299-58.778 0-81.101s-56.32-22.272-77.67 0z'%3E%3C/path%3E%3C/svg%3E");
}

.flatpickr-current-month .numInput.cur-year {
    @apply w-24 border-none bg-transparent;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    @apply relative w-auto appearance-none border-none rounded-none box-border cursor-pointer;
    @apply bg-transparent text-icoBlack dark:text-white text-base;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23899aaf' d='M725.504 339.661l-213.504 223.027-213.504-223.027c-21.402-22.272-56.32-22.272-77.67 0-9.911 10.585-15.998 24.857-15.998 40.55s6.087 29.965 16.028 40.583l-0.031-0.033 252.365 263.578c21.35 22.272 56.32 22.272 77.619 0l252.416-263.578c21.299-22.272 21.299-58.778 0-81.101s-56.32-22.272-77.67 0z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 14px 14px;
}

.flatpickr-current-month .numInput.cur-year,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
    @apply rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-icoBlue-200 dark:ring-offset-icoDarkMode-deepBlack;
}

.flatpickr-weekdays {
    @apply flex items-center overflow-hidden w-full mb-2;
    @apply bg-transparent;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
    @apply flex flex-1;
}

span.flatpickr-weekday {
    @apply flex flex-1 justify-center text-xs font-GBold;
    @apply text-icoBlack dark:text-white;
}

/* 
 * Datepicker day list 
 */
.flatpickr-days {
    @apply relative items-start flex overflow-hidden;
}

.dayContainer {
    @apply flex flex-wrap justify-around w-full box-border;
}

.flatpickr-day {
    @apply relative flex items-center leading-8 text-xs cursor-pointer overflow-hidden justify-center box-border font-GSemiBold;
    @apply text-icoBlack dark:text-icoDarkMode-wolf;
    width: 14.2857143%;
    -webkit-flex-basis: 14.2857143%;
    -ms-flex-preferred-size: 14.2857143%;
    flex-basis: 14.2857143%;
}

/* 
 * Prev/Next Month days | disabled | not allowed / 
 */
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
    @apply text-icoGray-400 cursor-default dark:text-icoDarkMode-wolf/80;
}

.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
    @apply text-icoGray-400 cursor-pointer dark:text-icoDarkMode-wolf/80;
}

/* 
 * Selected dates 
 */

.flatpickr-day.selected,
.flatpickr-day.endRange,
.flatpickr-day.startRange {
    @apply text-white;
}

.flatpickr-day.selected:before,
.flatpickr-day.endRange:before,
.flatpickr-day.startRange:before,
.flatpickr-day.selected.endRange:before,
.flatpickr-day.selected.startRange:before {
    content: '';
    @apply w-7 h-7 absolute rounded-full inset-1/2 -translate-y-1/2  -translate-x-1/2;
    @apply bg-icoBlue -z-1;
}

.flatpickr-day:not(.inRange):not(.selected):not(.startRange):not(.endRange):not(.flatpickr-disabled):hover:after,
.flatpickr-day.selected:after,
.flatpickr-day.endRange:after,
.flatpickr-day.startRange:after,
.flatpickr-day.selected.endRange:after,
.flatpickr-day.selected.startRange:after {
    content: '';
    @apply absolute;
    @apply bg-icoBlue-100 dark:bg-icoBlue/20;
    z-index: -2;
    width: 30px;
    height: 30px;
}

.flatpickr-day.endRange:after,
.flatpickr-day.startRange:after,
.flatpickr-day:not(.inRange):not(.selected):not(.startRange):not(.endRange):hover:after,
.flatpickr-day.selected:after {
    @apply rounded-full dark:text-icoDarkMode-wolf;
}

.flatpickr-day.startRange:not(.endRange):not(:nth-child(7n + 0)):after,
.flatpickr-day.selected.startRange:not(.endRange):not(:nth-child(7n + 0)):after {
    width: calc(50% + 15px);
    @apply rounded-tl-full rounded-bl-full rounded-tr-none rounded-br-none right-0;
}

.flatpickr-day.endRange:not(.startRange):not(:nth-child(7n + 1)):after,
.flatpickr-day.selected.endRange:not(.startRange):not(:nth-child(7n + 1)):after {
    width: calc(50% + 15px);
    @apply rounded-tr-full rounded-br-full rounded-tl-none rounded-bl-none left-0;
}

.flatpickr-day:not(.inRange):not(.selected):not(.startRange):not(.endRange):not(.flatpickr-disabled):hover,
.flatpickr-day.inRange:not(.startRange):not(.endRange) {
    @apply text-icoBlue;
}

.flatpickr-day.inRange:after {
    content: '';
    @apply absolute w-full;
    @apply bg-icoBlue-100 dark:bg-icoBlue/20;
    height: 30px;
    z-index: -2;
}

.flatpickr-day.inRange:nth-child(7n + 0):after {
    width: calc(50% + 15px);
    @apply rounded-tr-full rounded-br-full rounded-tl-none rounded-bl-none left-0;
}

.flatpickr-day.inRange:nth-child(7n + 1):after {
    width: calc(50% + 15px);
    @apply rounded-tl-full rounded-bl-full rounded-tr-none rounded-br-none right-0;
}

/**
 * Timepicker
 */

.flatpickr-calendar.hasTime.noCalendar {
    @apply w-full flex;
}

.flatpickr-time {
    @apply flex items-center;
}

.flatpickr-time .numInputWrapper {
    @apply flex-1;
}

.flatpickr-time .numInputWrapper .numInput {
    @apply w-full bg-transparent border-0 text-center h-12;
}

.flatpickr-time .numInputWrapper .numInput:focus {
    @apply bg-icoBlue-100 dark:bg-icoBlue/20 text-icoBlue ring-0;
}

.flatpickr-time .flatpickr-time-separator {
    @apply px-1;
}

.flatpickr-time .flatpickr-am-pm {
    @apply flex justify-center items-center h-12 cursor-pointer border-l border-icoGray-300 dark:border-icoDarkMode-beton px-1 w-20;
}

.flatpickr-time .arrowUp,
.flatpickr-time .arrowDown {
    @apply absolute inline-block top-1/2 -translate-y-1/2 w-5 h-3 bg-icoGray-100 dark:bg-icoDarkMode-anthracite bg-no-repeat bg-center cursor-pointer;
    background-size: 14px 14px;
}

.flatpickr-time .numInputWrapper .numInput:focus + .arrowUp,
.flatpickr-time .numInputWrapper .numInput:focus + .arrowUp + .arrowDown {
    @apply bg-icoBlue-100 dark:bg-icoBlue/20;
}

.flatpickr-time .numInputWrapper .numInput::-webkit-outer-spin-button,
.flatpickr-time .numInputWrapper .numInput::-webkit-inner-spin-button {
    @apply appearance-none;
}

.flatpickr-time .numInputWrapper .numInput[type='number'] {
    -moz-appearance: textfield;
}

.flatpickr-time .numInputWrapper .numInput {
    font-size: inherit;
}

.flatpickr-time .arrowUp {
    @apply right-2 -mt-1.5;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23899aaf' d='M725.504 684.339l-213.504-223.027-213.504 223.027c-21.402 22.272-56.32 22.272-77.67 0-9.911-10.585-15.998-24.857-15.998-40.55s6.087-29.965 16.028-40.583l-0.031 0.033 252.365-263.578c21.35-22.272 56.32-22.272 77.619 0l252.416 263.578c21.299 22.272 21.299 58.778 0 81.101s-56.32 22.272-77.67 0z'%3E%3C/path%3E%3C/svg%3E");
}

.flatpickr-time .arrowDown {
    @apply right-2 mt-1.5;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23899aaf' d='M725.504 339.661l-213.504 223.027-213.504-223.027c-21.402-22.272-56.32-22.272-77.67 0-9.911 10.585-15.998 24.857-15.998 40.55s6.087 29.965 16.028 40.583l-0.031-0.033 252.365 263.578c21.35 22.272 56.32 22.272 77.619 0l252.416-263.578c21.299-22.272 21.299-58.778 0-81.101s-56.32-22.272-77.67 0z'%3E%3C/path%3E%3C/svg%3E");
}

.arrow-on-hover .flatpickr-time .numInputWrapper .arrowUp,
.arrow-on-hover .flatpickr-time .numInputWrapper .arrowDown {
    @apply hidden;
}

.arrow-on-hover .flatpickr-time .numInputWrapper:hover .arrowUp,
.arrow-on-hover .flatpickr-time .numInputWrapper:hover .arrowDown {
    @apply block;
}
