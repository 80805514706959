
.rdp-table {
    @apply w-full;
}

/**
 * The style for the name of weekdays in the header of the calendar.
 */
.rdp-head_cell {
    @apply text-8 text-center leading-none text-icoBlack dark:text-white uppercase tracking-0.5 p-0 pb-1.5;
}

/**
 * Header
 */
.rdp-select_month {
    background-image: none;
}

/**
 * The style for days
 */
.rdp-cell {
    @apply p-0 text-center relative;
}

/* Style for day cell */
.rdp-day {
    @apply w-full h-6 text-xxs text-icoBlack dark:text-white align-top focus:ring-offset-0 focus:ring-0;
}

/* Style for day cell outside the current month */
.rdp-day.rdp-day_disabled {
    @apply text-icoGray-400 dark:text-icoDarkMode-wolf;
}

/* Hover day cell */ 
.rdp-day:not(.rdp-day_selected):not(.rdp-day_disabled):hover {
    @apply text-icoBlue;
}

.rdp-day:not(.rdp-day_selected):not(.rdp-day_disabled):hover::after {
    content: '';
    @apply bg-icoBlue-100 dark:bg-icoBlue/20 absolute w-5 h-5 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -z-1 rounded-full;
}


.day-picker-single .rdp-day_selected,
.rdp-day_selected.rdp-day_range_end, 
.rdp-day_selected.rdp-day_range_start {
    @apply text-white;
}

.day-picker-single .rdp-day_selected:before,
.rdp-day_selected.rdp-day_range_end::before, 
.rdp-day_selected.rdp-day_range_start::before {
    content: '';
    @apply bg-icoBlue absolute w-5 h-5 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -z-1 rounded-full;
}

.rdp-day_selected.rdp-day_range_end::after {
    @apply rounded-r-full left-0;
}

.rdp-day_selected.rdp-day_range_start::after {
    @apply rounded-l-full right-0;
}

.rdp-day_selected.rdp-day_range_middle {
    @apply text-icoBlue;
}

.rdp-day_selected.rdp-day_range_middle::after {
    content: '';
    @apply bg-icoBlue-100 dark:bg-icoBlue/20 w-full absolute top-1/2 -translate-y-1/2 left-0 -z-1 h-5;
}

.rdp-cell:last-child .rdp-day_selected.rdp-day_range_middle::after {
    width: calc(50% + 10px);
    @apply rounded-r-full left-0;
}

.rdp-cell:first-child .rdp-day_selected.rdp-day_range_middle::after {
    width: calc(50% + 10px);
    @apply rounded-l-full right-0 left-auto;
}

.rdp-cell:not(:first-child) .rdp-day.rdp-day_selected.rdp-day_range_end:not(.rdp-day_range_start):not(.rdp-day_disabled)::after,
.rdp-cell:not(:last-child) .rdp-day.rdp-day_selected.rdp-day_range_start:not(.rdp-day_range_end):not(.rdp-day_disabled)::after {
    content: '';
    @apply bg-icoBlue-100 dark:bg-icoBlue/20 absolute -z-2 top-1/2 -translate-y-1/2 h-5;
    width: calc(50% + 10px);
}